import React, { useEffect } from "react";
import { useGame } from "../../hooks/useGame";

import sucessIcon from "../../assets/img/icons/behaviour-type-success.svg";
import errorIcon from "../../assets/img/icons/behaviour-type-error.svg";
import { useUser } from "../../hooks/useUser";
import Loading from "../animations/Loading";

export default function GameAnwerExplanation({ questionId }) {
	useEffect(() => {
		document.querySelector("dialog").scrollTo(0, 0);
	}, []);
	const { answers, contextGameDatas, setCurrentGameModal, checkIfGameIsComplete } = useGame();
	const { screensTranslations } = useUser();

	if (!screensTranslations || !screensTranslations.tutorial) return <Loading />;
	const currentScreenTranslations = screensTranslations.game;

	const answer = answers[questionId];

	const answerExplanation =
		contextGameDatas.gameObjects[questionId].attrs.objectBehaviourDescription ??
		"Pas d'explication pour cette question";
	const answerBehaviour = answer.correctAnswer ?? "";
	const objectPictureUrl = contextGameDatas.gameObjects[questionId].attrs.objectPictureUrl;

	return (
		<div className='modal-content-container modal-content-container--grided answer-explanation-container  answer-explanation-container'>
			<div className='modal-content-container__content'>
				{answer.userAnsweredCorrectly === true && (
					<>
						<div></div>
						<h3 className='answer-explanation__type answer-explanation__type--success '>
							{currentScreenTranslations.right_answer}
							<img
								className='success-icon success-icon--success'
								src={sucessIcon}
								alt=''
							/>
						</h3>
					</>
				)}
				{answer.userAnsweredCorrectly === false && (
					<>
						<h3 className='answer-explanation__type answer-explanation__type--error'>
							{currentScreenTranslations.wrong_answer}
							<img
								className='success-icon success-icon--error'
								src={errorIcon}
								alt=''
							/>
						</h3>
					</>
				)}
				{answer.correctAnswer === "safe" && (
					<h2 className='answer-explanation__title'>
						{currentScreenTranslations.it_is_not_risk}
					</h2>
				)}
				{answer.correctAnswer === "unsafe" && (
					<h2 className='answer-explanation__title'>
						{currentScreenTranslations.it_is_risk}
					</h2>
				)}
				<p>{answerExplanation}</p>
				<button
					onClick={() => {
						setCurrentGameModal(null);
						checkIfGameIsComplete();
					}}
					className='continue-game cta cta--construction cta--round cta--button-icon cta--next'
				/>
			</div>
			<div
				className={`modal-content-container__cover modal-content-container__cover--${answerBehaviour}`}>
				<img src={objectPictureUrl} alt='' />
			</div>
		</div>
	);
}
