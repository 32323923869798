import React from "react";
import GameQuestion from "./GameQuestion";
import { useGame } from "../../hooks/useGame";

export default function GameObjects({ blocks }) {
	const { setCurrentGameModal, answers } = useGame();

	if (!blocks) return null;

	return (
		<>
			{blocks.map((block, id) => {
				const hasAnswered = answers && answers[id]?.userAnswer;
				const correctAnswerType = answers && answers[id]?.correctAnswer;

				return (
					<div
						className={`lhoist-blocks-focus-object ${
							hasAnswered ? "lhoist-blocks-focus-object--inactive" : ""
						} ${
							hasAnswered && correctAnswerType
								? "lhoist-blocks-focus-object--" + correctAnswerType
								: ""
						}  `}
						style={{
							height: `${block.attrs.objectScale}%`,
							top: `${block.attrs.objectPosition?.y * 100}%`,
							left: `${block.attrs.objectPosition?.x * 100}%`,
						}}
						key={id}>
						<img
							src={block.attrs.objectPictureUrl}
							alt={block.attrs.objectPictureAlt}
							draggable='false'
							onClick={() =>
								setCurrentGameModal(
									<GameQuestion
										questionId={id}
										correctAnswer={
											block.attrs.behaviourType
										}
									/>
								)
							}
						/>
					</div>
				);
			})}
		</>
	);
}
