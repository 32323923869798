import React from "react";
import Lottie from "lottie-react";
import { motion } from "framer-motion";

import animationPoints from "../../assets/animations/ecran-points.json";
import { useUser } from "../../hooks/useUser";
import Loading from "../animations/Loading";

export default function PointsAndTime({ setNextSlide, setPreviousSlide, startGame }) {
	const { screensTranslations } = useUser();

	if (!screensTranslations || !screensTranslations.tutorial) return <Loading />;
	const currentScreenTranslations = screensTranslations.tutorial;
	const uiTranslations = screensTranslations.ui;

	const animationParameters = {
		initial: { x: "100%", opacity: 0 },
		animate: { x: "0%", opacity: 1 },
		exit: { x: "-100%", opacity: 0 },
	};

	return (
		<motion.div
			key='tutorial-points'
			className='modal-content-container modal-content-container--grided tutorial-container'
			initial={animationParameters.initial}
			animate={animationParameters.animate}
			exit={animationParameters.exit}
			transition={{ duration: 0.4, ease: "easeOut" }}>
			<div className='modal-content-container__content'>
				<h3 className='titling-construction '>{currentScreenTranslations.how_to_play}</h3>
				<h2>{currentScreenTranslations.points_and_time}</h2>

				<p className='explanation'>{currentScreenTranslations.points_and_time_description}</p>
				<div className='tutorial-container__slides-controls'>
					<button
						title={uiTranslations.previous}
						className='cta cta--construction cta--round cta--button-icon cta--previous'
						onClick={setPreviousSlide}></button>
					<button
						title={uiTranslations.start}
						className='cta cta--construction cta--round cta--button-icon cta--play'
						onClick={() => {
							startGame();
						}}></button>
				</div>
			</div>
			<div className='modal-content-container__cover'>
				<Lottie
					className='tutorial-container__animated-cover'
					animationData={animationPoints}
					loop
					autoplay
				/>
			</div>
		</motion.div>
	);
}
