import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useUser } from "../../hooks/useUser";
import LanguageSelect from "./LanguageSelect";
import menuToggleIcon from "../../assets/img/icons/icon-bars-menu-toggle.svg";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Logo from "./AppLogoVector.jsx";
import Loading from "../animations/Loading";

export default function Nav() {
	let location = useLocation();

	const { screensTranslations } = useUser();

	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const handleMenuOpen = () => {
		setIsMenuOpen(!isMenuOpen);
	};

	const handleEscape = (e) => {
		if (e.key === "Escape") {
			setIsMenuOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener("keydown", handleEscape);
		return () => {
			document.removeEventListener("keydown", handleEscape);
		};
	}, []);

	const animationParameters = {
		initial: { y: "-100%" },
		animate: { y: "0" },
		exit: { y: "-100%" },
	};

	if (!screensTranslations || !screensTranslations.ui || !screensTranslations.pagesName) return <Loading />;
	const uiScreenTranslations = screensTranslations.ui;
	const pagesNamesTranslations = screensTranslations.pagesName;

	return (
		<nav className='main-menu'>
			{location && location.pathname !== "/Home" ? <Logo /> : null}
			<LanguageSelect />
			<button
				title={uiScreenTranslations.openMenu}
				className='nav-menu-toggle cta cta--button cta--button-icon'
				onClick={handleMenuOpen}>
				<img className='' src={menuToggleIcon} alt='' />
			</button>
			{isMenuOpen && (
				<motion.div
					key={location.pathname + "menu"}
					className='menu'
					open={isMenuOpen}
					initial={animationParameters.initial}
					animate={animationParameters.animate}
					exit={animationParameters.exit}
					transition={{ duration: 0.4, ease: "easeOut" }}>
					<button
						title={uiScreenTranslations.closeMenu}
						className='cta cta--construction cta--button-icon cta--close'
						onClick={handleMenuOpen}></button>

					<ul className='menu__main-links'>
						<li>
							<Link to='/about'>{pagesNamesTranslations.about}</Link>
						</li>
						<li>
							<Link to='/themes'>
								{pagesNamesTranslations.themes}{" "}
								<span className='span-construction'>interactive</span>
							</Link>
						</li>
					</ul>

					<ul className='menu__legal-mentions'>
						<li>
							<Link to='/confidentiality'>
								{pagesNamesTranslations.confidentiality}
							</Link>
						</li>
						<li>
							<Link to='/conditions'>
								{pagesNamesTranslations.conditions}
							</Link>
						</li>
						<li>
							<Link to='/data'>{pagesNamesTranslations.cookies}</Link>
						</li>
					</ul>
				</motion.div>
			)}
		</nav>
	);
}
