import { createContext, useEffect, useState } from "react";
import CountriesJSON from "../data/countries/countries_reduced.json";

export const UserContext = createContext();

export function UserContextProvider({ children }) {
	const [hasConsentedCookies, setHasConsentedCookies] = useState(
		localStorage.getItem("userCookiesConsent") || false
	);

	const localLanguage = localStorage.getItem("userLanguage") || "FR";
	const localCountry = JSON.parse(localStorage.getItem("userCountry")) || {
		name: "belgique",
		iso: "be",
		label: "Belgique",
	};
	const localProfile = localStorage.getItem("userProfile") || "lhoist_employee";

	const [language, setLanguage] = useState(localLanguage);
	const [country, setCountry] = useState(localCountry);
	const [profile, setProfile] = useState(localProfile);
	const [screensTranslations, setScreensTranslations] = useState({});

	useEffect(() => {
		if (!language) return;
		const loadTranslations = async (language) => {
			try {
				const translations = await import(`../data/screensTranslations_${language}.json`);
				setScreensTranslations(translations.default);
			} catch (error) {
				console.error("Could not load translations", error);
			}
		};

		// Appeler la fonction loadTranslations avec la langue actuelle
		loadTranslations(language);

		// ADAPTING COUNTRY LABEL TO LANGUAGE
		const currentCountry = CountriesJSON.find(
			(countryJson) => countryJson.alpha2 === country.iso.toLowerCase()
		);
		// setCountry({ ...country, label: currentCountry[language.toLowerCase()] });
		if (currentCountry && currentCountry[language.toLowerCase()] !== country.label) {
			setCountry({ ...country, label: currentCountry[language.toLowerCase()] });
		}
	}, [language, country]);

	function changeUserLanguage(newLanguage) {
		setLanguage(newLanguage);
		localStorage.setItem("userLanguage", newLanguage);
	}
	function changeCountry(newCountry) {
		setCountry(newCountry);
		localStorage.setItem("userCountry", JSON.stringify(newCountry));
	}

	function changeProfile(newProfile) {
		setProfile(newProfile);
		localStorage.setItem("userProfile", newProfile);
	}

	function acceptCookies() {
		setHasConsentedCookies(true);
		localStorage.setItem("userCookiesConsent", true);
	}
	return (
		<UserContext.Provider
			value={{
				language,
				screensTranslations,
				changeUserLanguage,
				country,
				hasConsentedCookies,
				setHasConsentedCookies,
				acceptCookies,
				setLanguage,
				changeCountry,
				profile,
				changeProfile,
			}}>
			{children}
		</UserContext.Provider>
	);
}
