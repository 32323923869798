import React from "react";
import helpIcon from "../../assets/img/icons/help-icon.svg";
import { useGame } from "../../hooks/useGame";

export default function Help() {
	const { hasCheckedTutorial, setHasCheckedTutorial } = useGame();

	return (
		<button
			className='help'
			title="afficher l'aide"
			onClick={() => setHasCheckedTutorial(!hasCheckedTutorial)}>
			<img className='help__icon' src={helpIcon} alt='' />
		</button>
	);
}
