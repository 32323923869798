import React from "react";
import LogoLhoist from "../../assets/img/logos/logo-lhoist.svg";

export default function AppLogo() {
	return (
		<a className='lhoist-branding-logo' href='https://www.lhoist.com/en' target='_blank' rel='noreferrer'>
			<img src={LogoLhoist} alt='' />
		</a>
	);
}
