import React, { useEffect, useState } from "react";
import GameObjects from "./GameObjects";
import { useGame } from "../../hooks/useGame";
import { useWordpressCustomData } from "../../services/WordpressFetchData.js";
import Score from "../../components/game/Score.jsx";
import Timer from "../../components/game/Timer.jsx";
import Help from "../../components/game/Help.jsx";
import Loading from "../../components/animations/Loading.jsx";

export default function GameContainer({ gameId }) {
	const gameDatas = useWordpressCustomData(`/screen/play/${gameId}`);

	const [isDragging, setIsDragging] = useState(false);
	const [startX, setStartX] = useState(0);

	const { contextGameDatas, setContextGameDatas, isGameComplete } = useGame();

	useEffect(() => {
		if (!gameDatas || gameDatas.error) return;
		setContextGameDatas(gameDatas);
	}, [gameDatas, setContextGameDatas]);

	useEffect(() => {
		if (!isGameComplete) return;
	}, [isGameComplete]);

	// HANDLE CANVAS DRAGGING INTERACTIONS
	function handleMouseDown(e) {
		const container = document.querySelector(".lhoist-blocks-search-and-find");
		setIsDragging(true);
		setStartX(e.clientX + container.scrollLeft);
	}

	function handleMouseMove(e) {
		if (!isDragging) return;
		const container = document.querySelector(".lhoist-blocks-search-and-find");
		const newScrollLeft = startX - e.clientX;
		container.scrollLeft = newScrollLeft;
	}

	function handleMouseUp() {
		setIsDragging(false);
	}

	if (
		!contextGameDatas ||
		!contextGameDatas.gameBlockDatas.attrs.coverUrl ||
		!contextGameDatas.gameBlockDatas.innerBlocks
	)
		return <Loading />;

	const gameBackgroundUrl = contextGameDatas.gameBlockDatas.attrs.coverUrl;
	const blocks = contextGameDatas.gameBlockDatas.innerBlocks;

	return (
		<>
			{/* <ExtraControls /> */}
			{/* <SoundControl /> */}
			<Score />
			<Timer />
			<Help />
			<div onMouseDown={handleMouseDown} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp}>
				<section className='wp-block-lhoist-blocks-search-and-find lhoist-blocks-search-and-find'>
					<div className='lhoist-blocks-search-and-find__objects-container'>
						<img
							className='lhoist-blocks-search-and-find__background_picture'
							src={gameBackgroundUrl}
							alt={""}
						/>
						<GameObjects blocks={blocks} />
					</div>
				</section>
			</div>
		</>
	);
}
