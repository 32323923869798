import Nav from "../components/ui/Nav";
import { postGameStatisticsData, postRatingData } from "../services/WordpressFetchData";
import { useState } from "react";

export default function Test() {
	const [randomData] = useState({});

	function postRandomDatas() {
		const requestDataExample = {
			user_locale: "DE",
			user_country: "France",
			level_post_id: 43,
			level_score: 1,
			level_completion_time: 800,
		};
		postGameStatisticsData(requestDataExample);
	}

	function postRandomRating() {
		const requestDataExample = {
			user_locale: "DE",
			user_country: "France",
			rating: 5,
		};
		postRatingData(requestDataExample);
	}

	console.log(randomData);
	return (
		<div className='page-container page-container--test'>
			<div className='screen screen--test'>
				<Nav />
				<button onClick={postRandomRating}> Post Random Rating</button>
				<div id='screen-container'>
					<button onClick={postRandomDatas}>Post Random Datas</button>
				</div>
			</div>
		</div>
	);
}
