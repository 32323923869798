import React, { useEffect, useState } from "react";
import AnimatedPage from "../components/AnimatedPage";
import { useNavigate } from "react-router-dom";
import { postRatingData } from "../services/WordpressFetchData";
import { useUser } from "../hooks/useUser";
import Loading from "../components/animations/Loading";

export default function Rate() {
	const [rating, setRating] = useState(undefined);

	const { screensTranslations } = useUser();

	useEffect(() => {
		return () => {
			setRating(undefined);
		};
	}, []);

	const navigate = useNavigate();

	if (!screensTranslations || !screensTranslations.game_results) return <Loading />;
	const currentScreenTranslations = screensTranslations.rating;

	function handleRatingChange(event) {
		// setRating({ ...rating, note: event.target.value });
		setRating({ ...rating, note: event.target.value });
	}

	function handleCommentChange(event) {
		setRating({ ...rating, comment: event.target.value });
	}

	function handleSubmit() {
		postRatingData(rating);
		navigate("/Home");
	}

	return (
		<AnimatedPage className='page-container page-container--rating'>
			<div className='screen '>
				<div className='screen__content'>
					<h1 className='titling-construction '>{currentScreenTranslations.title}</h1>
					<h2>{currentScreenTranslations.text}</h2>
					<div className='rating'>
						<div className='rating__items'>
							<input
								type='radio'
								name='stars'
								id='st5'
								value='5'
								onChange={handleRatingChange}
							/>
							<label htmlFor='st5'>
								<div className='star-fill'></div>

								<div
									className='label-description'
									data-content='Excellent'></div>
							</label>
							<input
								type='radio'
								name='stars'
								id='st4'
								value='4'
								onChange={handleRatingChange}
							/>
							<label htmlFor='st4'>
								<div className='star-fill'></div>

								<div
									className='label-description'
									data-content='Good'></div>
							</label>
							<input
								type='radio'
								name='stars'
								id='st3'
								value='3'
								onChange={handleRatingChange}
							/>
							<label htmlFor='st3'>
								<div className='star-fill'></div>

								<div
									className='label-description'
									data-content='OK'></div>
							</label>
							<input
								type='radio'
								name='stars'
								id='st2'
								value='2'
								onChange={handleRatingChange}
							/>
							<label htmlFor='st2'>
								<div className='star-fill'></div>

								<div
									className='label-description'
									data-content='Bad'></div>
							</label>
							<input
								type='radio'
								name='stars'
								id='st1'
								value='1'
								onChange={handleRatingChange}
							/>
							<label htmlFor='st1'>
								<div className='star-fill'></div>
								<div
									className='label-description'
									data-content='Terrible'></div>
							</label>
						</div>
					</div>

					<textarea
						onChange={handleCommentChange}
						placeholder={currentScreenTranslations.placeholder}
						name=''
						id=''
						cols='10'
						rows='2'></textarea>
					<button
						disabled={!rating}
						title={!rating ? "Attribuer une note!" : "Retour à l'acceuil!"}
						className='cta cta--construction cta--round cta--button-icon cta--back'
						onClick={handleSubmit}></button>
				</div>
			</div>
		</AnimatedPage>
	);
}
