import React from "react";
import { useWordpressCustomData } from "../services/WordpressFetchData";

import Nav from "../components/ui/Nav";
import AnimatedPage from "../components/AnimatedPage";
import Loading from "../components/animations/Loading";
import Footer from "../components/ui/Footer";
import { useUser } from "../hooks/useUser";

export default function ConditionsOfUse() {
	const pageDatas = useWordpressCustomData(`/page/177`);
	const { screensTranslations } = useUser();

	if (!pageDatas) return <Loading />;

	const uiTranslations = screensTranslations.ui;

	const dateObject = new Date(pageDatas.post_date);
	const formattedPostDate = dateObject.toLocaleDateString("fr-FR", {
		year: "numeric",
		month: "2-digit",
		day: "2-digit",
	});
	return (
		<AnimatedPage className='page-container page-container--cookies  '>
			<div className='content-page '>
				<Nav />

				<div className='editor_inner_content'>
					<div className='page-title'>
						<h1 className='page-title__title'>{pageDatas.post_title}</h1>
						<p className='page-title__last-update'>
							{uiTranslations?.last_update} {" : "} {formattedPostDate}
						</p>
					</div>
					<div
						className='editor_inner_content__wp-content'
						dangerouslySetInnerHTML={{ __html: pageDatas.post_content }}
					/>
				</div>
				<Footer />
			</div>
		</AnimatedPage>
	);
}
