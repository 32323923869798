import { useEffect, useState } from "react";
import axios from "axios";
import { useUser } from "../hooks/useUser";

// const BASE_URL = process.env.REACT_APP_BASE_URL;
const BASE_CUSTOM_URL = process.env.REACT_APP_BASE_CUSTOM_URL;

const fetchWordpressData = async (url) => {
	try {
		const response = await axios.get(url);

		return response.data;
	} catch (error) {
		console.error(error);
	}
};

const useWordpressCustomData = (url) => {
	const [data, setData] = useState();
	const { language } = useUser();

	const fullUrl = `${BASE_CUSTOM_URL}${url}?current-language=${language}`;

	useEffect(() => {
		fetchWordpressData(fullUrl).then((data) => {
			setData(data);
		});
	}, [language, fullUrl]);
	return data;
};

const useWordpressData = (url) => {
	const [data, setData] = useState();

	useEffect(() => {
		fetchWordpressData(url).then((data) => {
			setData(data);
		});
	}, [url]);

	return data;
};

const postGameStatisticsData = async (requestDatas) => {
	const options = {
		method: "POST",
		url: BASE_CUSTOM_URL + "/statistics/post",
		data: requestDatas,
	};

	axios.request(options)
		.then((response) => {})
		.catch((error) => {
			console.error(error);
		});
};

const postRatingData = async (requestDatas) => {
	const options = {
		method: "POST",
		url: BASE_CUSTOM_URL + "/rating/post",
		data: requestDatas,
	};

	axios.request(options)
		.then((response) => {
			console.log(response.data);
		})
		.catch((error) => {
			console.error(error);
		});
};

export { useWordpressData, useWordpressCustomData, postGameStatisticsData, postRatingData };
