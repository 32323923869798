import React from "react";
import Modal from "../../components/ui/Modal.jsx";
import cookiesModalCover from "../../assets/img/illustrations/cookies-modal-cover.svg";
import { Link } from "react-router-dom";
import { useUser } from "../../hooks/useUser.jsx";

export default function CookiesModal() {
	const { screensTranslations, hasConsentedCookies, setHasConsentedCookies, acceptCookies } = useUser();

	if (
		!screensTranslations ||
		!screensTranslations.pagesName ||
		!screensTranslations.cookies ||
		!screensTranslations.ui
	)
		return <p>loading</p>;
	const cookiesTranslations = screensTranslations.cookies;
	const pagesName = screensTranslations.pagesName;
	const uiTranslations = screensTranslations.ui;

	return (
		<Modal
			unclosable
			className='classic-modal'
			id='modal-cookies'
			open={!hasConsentedCookies}
			onClose={setHasConsentedCookies}>
			<div className='modal-content-container modal-content-container--centered '>
				<div className='modal-content-container__content'>
					<img
						className='modal-content-container__modal-cover'
						src={cookiesModalCover}
						alt='close'
					/>
					<h3 className='titling-construction '>{cookiesTranslations.title}</h3>
					<p className='explanation'>{cookiesTranslations.description}</p>
					<div className='pages-redirection'>
						<Link to='/confidentiality' className='classic-link'>
							{pagesName.confidentiality}
						</Link>
						<Link to='/data' className='classic-link'>
							{pagesName.cookies}
						</Link>
					</div>
					<button
						className='cta cta--lhoist cta--schief cta--zuume cta--spaced-normal'
						onClick={acceptCookies}>
						{uiTranslations.continue}
					</button>
				</div>
			</div>
		</Modal>
	);
}
