import React from "react";
import AnimatedPage from "../components/AnimatedPage";
import Nav from "../components/ui/Nav";
import { useUser } from "../hooks/useUser";

import img404 from "../assets/img/illustrations/404.svg";
export default function PageNotFound() {
	const { screensTranslations } = useUser();

	if (!screensTranslations || !screensTranslations.screen_404) return <p>loading</p>;
	const screen404Translations = screensTranslations.screen_404;

	return (
		<AnimatedPage className='page-container page-container--not-found  '>
			<div className='content-page'>
				<Nav />
				<img className='cover' id='test' src={img404} alt='' />
				<h1>{screen404Translations.page_not_found}</h1>
				<p className='error-description'>{screen404Translations.explanation}</p>
			</div>
		</AnimatedPage>
	);
}
