import React from "react";
import Lottie from "lottie-react";

import loadingTruck from "../../assets/animations/chargement-vehicules-boom.json";
import { useUser } from "../../hooks/useUser";

export default function Loading() {
	const { screensTranslations } = useUser();

	if (!screensTranslations || !screensTranslations?.ui?.loading) return;

	return (
		<div className='loading'>
			<Lottie className='loading__animation' animationData={loadingTruck} loop autoplay />
			<p className='loading__text'>{screensTranslations.ui.loading}...</p>
		</div>
	);
}
