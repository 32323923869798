import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { UserContextProvider } from "./context/UserContext.js";
import { BrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

const root = ReactDOM.createRoot(document.getElementById("root"));

function fallbackRender({ error, resetErrorBoundary }) {
	return (
		<div role='alert'>
			<p>Something went wrong:</p>
			<pre style={{ color: "red" }}>{error.message}</pre>
		</div>
	);
}

root.render(
	<React.StrictMode>
		<UserContextProvider>
			<BrowserRouter>
				<ErrorBoundary fallbackRender={fallbackRender}>
					<App />
				</ErrorBoundary>
			</BrowserRouter>
		</UserContextProvider>
	</React.StrictMode>
);
