import { createContext, useEffect, useState, useCallback } from "react";
// import chantierAtmopshere from "../assets/sounds/chantier_1.mp3";
import GameAnswerExplanation from "../components/game/GameAnswerExplanation.jsx";
import { useUser } from "../hooks/useUser.jsx";
import { calculateScore } from "../utils/gameFunctions.js";
import { postGameStatisticsData } from "../services/WordpressFetchData.js";
import { useNavigate } from "react-router-dom";

export const GameContext = createContext();

export function GameContextProvider({ children }) {
	const navigate = useNavigate();

	const hasAlreadyCheckedTutorial = localStorage.getItem("hasAlreadyCheckedTutorial") || false;

	const { language, country } = useUser();

	// ##### DATA #####
	const [contextGameDatas, setContextGameDatas] = useState(null);

	// ##### GAME #####
	const [answers, setAnswers] = useState(null);
	const [score, setScore] = useState(null);
	const [gameTime, setGameTime] = useState(0);
	const [remainingQuestionsAmount, setRemainingQuestionsAmount] = useState(null);

	// ##### INTERFACE #####
	const [hasCheckedTutorial, setHasCheckedTutorial] = useState(hasAlreadyCheckedTutorial);
	const [currentGameModal, setCurrentGameModal] = useState(null);
	const [isSoundOn, setIsSoundOn] = useState(true);

	// ##### STATUS #####
	const [isTimeRuning, setIsTimeRuning] = useState(false);
	const [isGameComplete, setIsGameComplete] = useState(false);

	// ##### INIT DATAS #####
	const initAnswers = useCallback(() => {
		const answersArray = contextGameDatas.gameObjects.map((object, key) => {
			return {
				correctAnswer: object.attrs.behaviourType ? object.attrs.behaviourType : "unsafe",
				userAnswer: null,
				userAnsweredCorrectly: null,
			};
		});
		setAnswers(answersArray);
	}, [contextGameDatas]);

	useEffect(() => {
		if (!contextGameDatas) return;
		initAnswers();
		setRemainingQuestionsAmount(contextGameDatas.gameObjects.length);
	}, [contextGameDatas, initAnswers]);

	function answerQuestion(answerKey, answer) {
		if (!answer || answerKey === null) return;
		const newAnswers = [...answers];
		newAnswers[answerKey].userAnswer = answer;
		newAnswers[answerKey].userAnsweredCorrectly = answer === newAnswers[answerKey].correctAnswer;
		setAnswers(newAnswers);
		setRemainingQuestionsAmount(remainingQuestionsAmount - 1);
		const newScore = calculateScore(answers);
		setScore(newScore);
		setCurrentGameModal(<GameAnswerExplanation questionId={answerKey} />);
	}

	function checkIfGameIsComplete() {
		const isGameFinished = answers.every((answer) => answer.userAnswer !== null);
		if (!isGameFinished) return;
		endGame();
	}

	function endGame() {
		setIsTimeRuning(false);
		setIsGameComplete(true);
		const gameStats = buildGameStatsObject();
		postGameStatisticsData(gameStats);
		// navigate("/results");
		navigate("/results", { state: { gameStats, contextGameDatas } });
	}

	function buildGameStatsObject() {
		if (!language || !country || !country.name || !contextGameDatas.gameId) return null;
		return {
			user_locale: language,
			user_country: country.name,
			level_post_id: contextGameDatas.gameId ?? null,
			level_score: score ?? 0,
			level_total_questions: answers.length,
			level_score_range:
				score === answers.length
					? "perfect"
					: score >= answers.length / 2
					? "congrats"
					: "attention",
			level_completion_time: gameTime,
		};
	}

	function resetGame() {
		setIsGameComplete(false);
		setIsTimeRuning(false);
	}

	return (
		<GameContext.Provider
			value={{
				currentGameModal,
				score,

				gameTime,
				setGameTime,
				setCurrentGameModal,
				contextGameDatas,
				setContextGameDatas,
				isTimeRuning,
				setIsTimeRuning,
				isGameComplete,
				answers,
				resetGame,
				setAnswers,
				initAnswers,
				isSoundOn,
				setIsSoundOn,
				remainingQuestionsAmount,
				buildGameStatsObject,
				answerQuestion,
				hasCheckedTutorial,
				setHasCheckedTutorial,
				checkIfGameIsComplete,
			}}>
			{children}
		</GameContext.Provider>
	);
}
