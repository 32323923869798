import { useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useUser } from "../../hooks/useUser.jsx";
import CountriesJSON from "../../data/countries/countries_reduced.json";

export default function CountrySelect({ label }) {
	const { country, changeCountry } = useUser();
	const { language } = useUser();
	const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

	function handleChangeCountry(country) {
		changeCountry({ name: country.fr, iso: country.alpha2, label: country[language.toLowerCase()] });
		handleShowHideSubmenu();
	}
	function handleShowHideSubmenu() {
		setIsSubmenuOpen(!isSubmenuOpen);
	}

	const checkEsc = useCallback((event) => {
		if (event.key === "Escape") {
			setIsSubmenuOpen(false);
		}
		if (event.type === "click") {
			// !event.target.closest(".country-select")
			setIsSubmenuOpen(false);
		}
	}, []);
	const checkClick = useCallback((event) => {
		if (event.type === "click" && !event.target.closest(".country-select")) {
			setIsSubmenuOpen(false);
		}
	}, []);

	useEffect(() => {
		document.addEventListener("keydown", checkEsc, false);
		document.addEventListener("click", checkClick, false);

		return () => {
			document.removeEventListener("keydown", checkEsc, false);
			document.removeEventListener("click", checkClick, false);
		};
	}, [checkEsc, checkClick]);

	const countryAlphabeticList = CountriesJSON.sort((a, b) =>
		a[language.toLowerCase()].localeCompare(b[language.toLowerCase()])
	);

	const countryOptions = countryAlphabeticList.map((country) => (
		<li key={uuidv4()} className='country-select__option' onClick={() => handleChangeCountry(country)}>
			<img className='flag' src={`/img/flags/32x32/${country.alpha2}.png`} alt={country.fr} />
			{country[language.toLowerCase()]}
		</li>
	));

	if (!countryOptions) return;

	return (
		<div className='country-select'>
			<p className='country-select__label'>{label}</p>
			<div>
				<button className='country-select__toggle' onClick={handleShowHideSubmenu}>
					<img
						className='flag'
						src={`/img/flags/32x32/${country.iso}.png`}
						alt={country.fr}
					/>
					{country.label}
				</button>
				<ul className='country-select__country-list' aria-hidden={!isSubmenuOpen}>
					{countryOptions}
				</ul>
			</div>
		</div>
	);
}
