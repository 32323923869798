import React from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../hooks/useUser";

import Nav from "../components/ui/Nav";
import AnimatedPage from "../components/AnimatedPage";

import lhoistEmployee from "../assets/img/profiles/lhoist-employee.svg";
import subcontractorEmployee from "../assets/img/profiles/subcontractor-employee.svg";
import driver from "../assets/img/profiles/driver.svg";
import visitor from "../assets/img/profiles/visitor.svg";
import Loading from "../components/animations/Loading.jsx";

export default function Profile() {
	const navigate = useNavigate();

	const { profile, changeProfile } = useUser();

	const { screensTranslations } = useUser();

	if (!screensTranslations || !screensTranslations.profile) return <Loading />;
	const currentScreenTranslations = screensTranslations.profile;
	const uiTranslations = screensTranslations.ui;

	function handleChangeProfile(e) {
		changeProfile(e.target.getAttribute("value"));
	}

	return (
		<AnimatedPage className='page-container page-container--profile page-container-has-screen'>
			<div className='screen screen--profile'>
				<Nav />
				<section className='screen__content'>
					<h1 className='titling-construction'>{currentScreenTranslations.profile}</h1>
					<h2 className='screen-subtitle '>{currentScreenTranslations.iam}...</h2>

					<ul className='profile-select'>
						<li
							className='profile-select__profile-type'
							value='lhoist_employee'
							is-current={profile === "lhoist_employee" ? "true" : ""}
							onClick={handleChangeProfile}>
							<img src={lhoistEmployee} alt='' />
							<h3 className='titling-construction'>
								{currentScreenTranslations.profiles.lhoist_employee}
							</h3>
						</li>
						<li
							className='profile-select__profile-type'
							value='subcontractor_employee'
							is-current={profile === "subcontractor_employee" ? "true" : ""}
							onClick={handleChangeProfile}>
							<img src={subcontractorEmployee} alt='' />
							<h3 className='titling-construction'>
								{
									currentScreenTranslations.profiles
										.subcontractor_employee
								}
							</h3>
						</li>
						<li
							className='profile-select__profile-type'
							value='driver'
							is-current={profile === "driver" ? "true" : ""}
							onClick={handleChangeProfile}>
							<img src={driver} alt='' />
							<h3 className='titling-construction'>
								{currentScreenTranslations.profiles.driver}
							</h3>
						</li>
						<li
							className='profile-select__profile-type'
							value='visitor'
							is-current={profile === "visitor" ? "true" : ""}
							onClick={handleChangeProfile}>
							<img src={visitor} alt='' />
							<h3 className='titling-construction'>
								{currentScreenTranslations.profiles.visitor}
							</h3>
						</li>
					</ul>

					<button
						className='cta cta--construction cta--round cta--button-icon cta--next  '
						title={uiTranslations.next}
						onClick={() => {
							navigate("/select-country");
						}}></button>
				</section>
			</div>
		</AnimatedPage>
	);
}
