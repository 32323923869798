import React from "react";
import { useEffect, useRef } from "react";

export default function Modal({ open, onClose, children, className, id, closeModal, unclosable }) {
	const modalRef = useRef(null);

	useEffect(() => {
		const { current: el } = modalRef;
		el.showModal();
	}, []);

	useEffect(() => {
		const { current: el } = modalRef;
		if (open) el.showModal();
		else el.close();
	}, [open]);

	useEffect(() => {
		if (!unclosable || unclosable === "false") return;
		const { current: currentElement } = modalRef;
		currentElement.addEventListener("cancel", (event) => {
			event.preventDefault();
		});
	}, [unclosable]);

	return (
		<dialog
			id={id}
			className={className}
			ref={modalRef}
			onClose={onClose}
			unclosable={unclosable ? "true" : "false"}>
			{children}
		</dialog>
	);
}
