import { useParams } from "react-router-dom";
import { GameContextProvider } from "../context/GameContext.js";

import GameContainer from "../components/game/GameContainer.jsx";

import Tutorials from "../components/tutorials/Tutorials.jsx";
import AppLogo from "../components/ui/AppLogoVector.jsx";
import GameModal from "../components/game/GameModal.jsx";
import AnimatedPage from "../components/AnimatedPage.jsx";

export default function Game() {
	const { id } = useParams();
	const gameId = id || "latest";
	return (
		<>
			<AnimatedPage className='page-container page-container--play'>
				<GameContextProvider>
					<div className='screen screen--game'>
						<AppLogo />
						<GameModal />
						<Tutorials />
						<GameContainer gameId={gameId} />
					</div>
				</GameContextProvider>
			</AnimatedPage>
		</>
	);
}
