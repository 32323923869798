import React from "react";

import Nav from "../components/ui/Nav";
import AnimatedPage from "../components/AnimatedPage";
import Footer from "../components/ui/Footer";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-cards";

import { Pagination, Navigation, EffectCards } from "swiper/modules";

import { useWordpressCustomData } from "../services/WordpressFetchData";
import { Link } from "react-router-dom";
import Loading from "../components/animations/Loading";
import { useUser } from "../hooks/useUser";

export default function Thematiques() {
	const gamesDatas = useWordpressCustomData(`/screen/play/all`);

	const { screensTranslations } = useUser();

	if (!screensTranslations || !screensTranslations.ui || !screensTranslations.pagesName) return <Loading />;
	const uiScreenTranslations = screensTranslations.ui;
	const pagesNamesTranslations = screensTranslations.pagesName;

	return (
		<AnimatedPage className='page-container page-container--thematiques  '>
			<div className='content-page '>
				<Nav />
				<div className='inner-content'>
					<div className='page-title'>
						<h1 className='page-title__title'>{pagesNamesTranslations.themes}</h1>
						<div className='page-title__span-construction'>Interactive</div>
					</div>

					<div className='swiper-container'>
						<Swiper
							effect={"cards"}
							// slidesPerView={2}
							navigation={true}
							centeredSlides={true}
							// spaceBetween={30}
							keyboard={{
								enabled: true,
							}}
							pagination={{
								clickable: true,
							}}
							grabCursor={true}
							modules={[Pagination, Navigation, EffectCards]}
							className='mySwiper'>
							{gamesDatas &&
								gamesDatas.map((game, index) => (
									<SwiperSlide key={index} className='game-slide'>
										<div className='game-slide__cover'>
											<img
												src={game.gameCover}
												alt=''
											/>
										</div>
										<h2 className='game-slide__title'>
											<Link
												to={`/play/${game.gameId}`}>
												{game.gameTitle}
											</Link>
										</h2>

										<Link
											to={`/play/${game.gameId}`}
											className='cta cta--construction cta--round cta--button-icon cta--play'></Link>

										{game.gamePrintPdf && (
											<a
												href={game.gamePrintPdf}
												target='_blank'
												rel='noreferrer'
												className='cta cta--lhoist cta--button-icon cta--download'>
												{
													uiScreenTranslations.download
												}
											</a>
										)}
									</SwiperSlide>
								))}
						</Swiper>
					</div>
				</div>
				<Footer />
			</div>
		</AnimatedPage>
	);
}
