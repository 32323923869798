import { Link } from "react-router-dom";
import React from "react";
import AnimatedPage from "../components/AnimatedPage";
import Loading from "../components/animations/Loading";
import { useUser } from "../hooks/useUser";
import warningSticker from "../assets/img/illustrations/lhoist-prevention-smartphone-sticker.svg";
import BrandingLogo from "../components/ui/LhoistBrandingLogo.jsx";

export default function Warning() {
	const { screensTranslations } = useUser();
	if (!screensTranslations || !screensTranslations.warning) return <Loading />;
	const currentScreenTranslations = screensTranslations.warning;
	const uiTranslations = screensTranslations.ui;

	return (
		<AnimatedPage className='page-container page-container--warning'>
			<h1 className='warning-content__title'>{currentScreenTranslations.warning}</h1>
			<img className='warning-content__cover' src={warningSticker} alt='' />
			<p className='warning-content__text'>{currentScreenTranslations.safety_text}</p>
			<Link
				to='/home'
				title={currentScreenTranslations.continue}
				className='cta cta--construction cta--lhoist cta--button-icon cta--start'>
				<span>{uiTranslations.continue}</span>
			</Link>
			<BrandingLogo />
		</AnimatedPage>
	);
}
