function calculateScore(answers) {
	const correctAnswers = answers.filter((answer) => answer.userAnsweredCorrectly === true);
	const score = correctAnswers.length;
	return score;
}
function formatCurrentTime(currentTime) {
	// Minutes calculation
	const minutes = Math.floor(currentTime / 60)
		.toString()
		.padStart(2, "0");

	// Seconds calculation
	const seconds = Math.floor(currentTime % 60)
		.toString()
		.padStart(2, "0");

	return `${minutes} : ${seconds}`;
}

export { calculateScore, formatCurrentTime };
