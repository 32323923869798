import React from "react";
import CountrySelect from "../components/ui/CountrySelect";
import Nav from "../components/ui/Nav";
import { motion } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";

// import screensTranslations from "../data/screensTranslations.json";
import { useUser } from "../hooks/useUser";

export default function Country() {
	const navigate = useNavigate();
	const location = useLocation();

	const { screensTranslations } = useUser();

	if (!screensTranslations || !screensTranslations.country) return <p>loading</p>;
	const currentScreenTranslations = screensTranslations.country;
	const uiTranslations = screensTranslations.ui;

	return (
		<motion.div
			className='page-container page-container--profile page-container-has-screen'
			initial={{ x: "50%", opacity: 0.7 }}
			animate={{ x: "0%", opacity: 1 }}
			exit={{ x: "-100%", opacity: 1 }}
			transition={{ duration: 0.3, ease: "easeOut" }}>
			<div className='screen screen--profile'>
				<Nav />
				<section className='screen__content'>
					<h1 className='titling-construction'>{currentScreenTranslations.profile}</h1>
					<h2 className='screen-subtitle '>{currentScreenTranslations.iamfrom}...</h2>

					<CountrySelect label={currentScreenTranslations.country_label} />
					<button
						className='cta cta--construction cta--round cta--button-icon cta--next  '
						title={uiTranslations.next}
						onClick={() => {
							navigate("/play", {
								state: { previousUrl: location.pathame, isNext: true },
							});
						}}></button>
				</section>
			</div>
		</motion.div>
	);
}
