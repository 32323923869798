import React, { useEffect, useState } from "react";
import Modal from "../ui/Modal.jsx";
import { useGame } from "../../hooks/useGame.jsx";
import MoveAround from "./MoveAround.jsx";
import PointsAndTime from "./PointsAndTime.jsx";
import Walkthrough from "./Walkthrough.jsx";

export default function Tutorials() {
	const { hasCheckedTutorial, setHasCheckedTutorial, setIsTimeRuning } = useGame();

	const [activeSlide, setActiveSlide] = useState(0);

	useEffect(() => {
		document.querySelector("dialog").scrollTo(0, 0);
	}, []);

	function setNextSlide() {
		setActiveSlide(activeSlide + 1);
		document.querySelector("dialog").scrollTo(0, 0);
	}
	function setPreviousSlide() {
		setActiveSlide(activeSlide - 1);
		document.querySelector("dialog").scrollTo(0, 0);
	}
	function startGame() {
		localStorage.setItem("hasAlreadyCheckedTutorial", true);

		setIsTimeRuning(true);
		setHasCheckedTutorial(true);
	}

	return (
		<div>
			<Modal open={!hasCheckedTutorial} onClose={startGame}>
				{activeSlide === 0 && <MoveAround setNextSlide={setNextSlide} />}
				{activeSlide === 1 && (
					<Walkthrough setNextSlide={setNextSlide} setPreviousSlide={setPreviousSlide} />
				)}
				{activeSlide === 2 && (
					<PointsAndTime setPreviousSlide={setPreviousSlide} startGame={startGame} />
				)}
			</Modal>
		</div>
	);
}
