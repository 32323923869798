import React from "react";

export default function Marquee({ children }) {
	return (
		<div className='marquee'>
			<div>
				<p>
					<span lang='fr'>Restez en sécurité</span>
					<span lang='en'>Stay Safe</span>
					<span lang='es'>Mantente a salvo</span>
					<span lang='pt'>Fique seguro</span>
					<span lang='de'>Bleib sicher</span>
					<span lang='da'>Vær sikker</span>
					<span lang='fi'>Pysy turvassa</span>
					<span lang='pl'>Bądź bezpieczny</span>
					<span lang='cs'>Buď v bezpečí</span>
					<span lang='sk'>Buďte v bezpeí</span>
					<span lang='ms'>Kekal selamat</span>
				</p>
				<p>
					<span lang='fr'>Restez en sécurité</span>
					<span lang='en'>Stay Safe</span>
					<span lang='es'>Mantente a salvo</span>
					<span lang='pt'>Fique seguro</span>
					<span lang='de'>Bleib sicher</span>
					<span lang='da'>Vær sikker</span>
					<span lang='fi'>Pysy turvassa</span>
					<span lang='pl'>Bądź bezpieczny</span>
					<span lang='cs'>Buď v bezpečí</span>
					<span lang='sk'>Buďte v bezpeí</span>
					<span lang='ms'>Kekal selamat</span>
				</p>
			</div>
		</div>
	);
}
