import React from "react";
import appLogo from "../../assets/img/logos/app_logo.svg";
// import appLogo from "../../assets/img/logos/app_logo_beta.svg";
import { Link } from "react-router-dom";

export default function Logo() {
	return (
		<Link to='/' className='app-logo-vector'>
			<img src={appLogo} alt='' />
		</Link>
	);
}
