import React from "react";
import anwserIconSafe from "../../assets/img/icons/anwser_icon_safe.svg";
import anwserIconUnsafe from "../../assets/img/icons/anwser_icon_unsafe.svg";

import { useGame } from "../../hooks/useGame";
import { useUser } from "../../hooks/useUser";
import Loading from "../animations/Loading";

export default function GameQuestion({ questionId, correctAnswer }, ...props) {
	const { answerQuestion, contextGameDatas } = useGame();
	const { screensTranslations } = useUser();

	if (!screensTranslations || !screensTranslations.tutorial) return <Loading />;
	const currentScreenTranslations = screensTranslations.game;

	const objectPictureUrl = contextGameDatas.gameObjects[questionId].attrs.objectPictureUrl;

	function handleAnswer(questionId, answer) {
		answerQuestion(questionId, answer);
	}

	return (
		<div className='modal-content-container modal-content-container--grided game-question-container '>
			<div className='modal-content-container__content'>
				<h3 className='titling-construction '>{currentScreenTranslations.found}</h3>
				<h2>{currentScreenTranslations.is_it_secure}</h2>

				<p className='question'>{currentScreenTranslations.is_it_secure_question}</p>
				<div className='game-question-container__answer-buttons-container'>
					<button
						className='game-question-container__answer-button'
						onClick={() => handleAnswer(questionId, "safe")}>
						<img src={anwserIconSafe} alt='' />
					</button>
					<button
						className='game-question-container__answer-button'
						onClick={() => handleAnswer(questionId, "unsafe")}>
						<img src={anwserIconUnsafe} alt='' />
					</button>
				</div>
				{/* <p className='pt-8'>{correctAnswer}</p> */}
			</div>
			<div className='modal-content-container__cover'>
				<img src={objectPictureUrl} alt='' />
			</div>
		</div>
	);
}
