import React, { useEffect } from "react";
import clock from "../../assets/img/illustrations/clock.svg";
import { useGame } from "../../hooks/useGame";
import { formatCurrentTime } from "../../utils/gameFunctions";

export default function Timer() {
	const { hasCheckedTutorial, isTimeRuning } = useGame();

	const { setGameTime, gameTime } = useGame();

	useEffect(() => {
		if (!hasCheckedTutorial || !isTimeRuning) return;
		let intervalId;
		intervalId = setInterval(() => setGameTime(gameTime + 1), 1000);
		// setCurrentTime(currentTime);

		return () => clearInterval(intervalId);
	}, [hasCheckedTutorial, gameTime, isTimeRuning, setGameTime]);

	const gameFormatedTime = formatCurrentTime(gameTime);

	return (
		<div className='timer'>
			<div className='bg'></div>
			<img className='clock' src={clock} alt='' />
			<h2>{gameFormatedTime}</h2>
		</div>
	);
}
