import { Link } from "react-router-dom";
import AppLogoMarkup from "../components/ui/AppLogoMarkup";
import AnimatedPage from "../components/AnimatedPage";
import { useUser } from "../hooks/useUser";
import LanguageSelect from "../components/ui/LanguageSelect";

import truckAnimation from "../assets/animations/chargement-vehicules";
import Lottie from "lottie-react";
import Loading from "../components/animations/Loading";

export default function Welcome() {
	const { screensTranslations } = useUser();

	if (!screensTranslations || !screensTranslations.welcome) return <Loading />;
	const currentScreenTranslations = screensTranslations.welcome;

	return (
		<AnimatedPage className='page-container page-container--welcome'>
			<AppLogoMarkup />
			<Lottie className='truck-animation' animationData={truckAnimation} loop autoplay />
			<div className='interaction-buttons'>
				<LanguageSelect />
				<Link
					to='/warning'
					title={currentScreenTranslations.enter}
					className='cta cta--construction cta--lhoist cta--button-icon cta--start'>
					<span>{currentScreenTranslations.enter}</span>
				</Link>
			</div>
		</AnimatedPage>
	);
}
