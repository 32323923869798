import React from "react";
import { useGame } from "../../hooks/useGame";
import anwserIconSafe from "../../assets/img/icons/anwser_icon_safe.svg";
import anwserIconUnsafe from "../../assets/img/icons/anwser_icon_unsafe.svg";
import sucessIcon from "../../assets/img/icons/behaviour-type-success.svg";
import errorIcon from "../../assets/img/icons/behaviour-type-error.svg";

export default function Score() {
	let { answers } = useGame();
	if (!answers) return;
	return (
		<div className='score'>
			<ul className='answers-visualiser'>
				{answers.map((answer, key) => {
					const answerType = !answer.userAnswer
						? "unanswered"
						: answer.correctAnswer === answer.userAnswer
						? "correct"
						: "incorrect";
					return (
						<li className={`answer answer--${answerType}`} key={key}>
							{answer.userAnsweredCorrectly === true && (
								<img
									className='answer__sucess-indicator answer__sucess-indicator--success'
									src={sucessIcon}
									alt=''
								/>
							)}
							{answer.userAnsweredCorrectly === false && (
								<img
									className='answer__sucess-indicator answer__sucess-indicator--error'
									src={errorIcon}
									alt=''
								/>
							)}

							{answer.userAnswer && answer.correctAnswer === "safe" && (
								<img
									className='answer__behaviour-type-icon'
									src={anwserIconSafe}
									alt=''
								/>
							)}
							{answer.userAnswer && answer.correctAnswer === "unsafe" && (
								<img
									className='answer__behaviour-type-icon'
									src={anwserIconUnsafe}
									alt=''
								/>
							)}
						</li>
					);
				})}
			</ul>
		</div>
	);
}
