import React from "react";

import Nav from "../components/ui/Nav";
import AnimatedPage from "../components/AnimatedPage";
import { useLocation } from "react-router-dom";
import ResultsModals from "../components/results/ResultsModals";
import { useNavigate } from "react-router-dom";

export default function Results() {
	let location = useLocation();

	const navigate = useNavigate();
	if (!location.state) return navigate("/play");

	const { contextGameDatas } = location.state;

	return (
		<AnimatedPage className='page-container page-container--results'>
			<div
				className='screen '
				style={{ backgroundImage: `url("${contextGameDatas.gameBlockDatas.attrs.coverUrl}")` }}>
				<Nav />
				<ResultsModals resultsDatas={location.state} />
				{/* <div id='screen-container'></div> */}
			</div>
		</AnimatedPage>
	);
}
