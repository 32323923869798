import React from "react";
import { useGame } from "../../hooks/useGame";
import Modal from "../../components/ui/Modal.jsx";
import closeIcon from "../../assets/img/icons/icon-cross.svg";

export default function GameModal() {
	const { currentGameModal, setCurrentGameModal, remainingQuestionsAmount } = useGame();
	if (!currentGameModal) return null;

	return (
		<Modal
			className='game-modal'
			open={currentGameModal}
			onClose={() => setCurrentGameModal(null)}
			unclosable={remainingQuestionsAmount < 1 ? "true" : "false"}>
			<button className='game-modal__close-button' onClick={() => setCurrentGameModal(null)}>
				<img className='close-icon' src={closeIcon} alt='close' />
			</button>
			{currentGameModal}
		</Modal>
	);
}
