import React, { useEffect, useState } from "react";
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";
import Loading from "../animations/Loading";

import Modal from "../../components/ui/Modal.jsx";
import cup from "../../assets/img/illustrations/cup.svg";
import celebrationConfettis from "../../assets/animations/trophee-confettis.json";
import celebrationConfettisFixe from "../../assets/animations/trophee-confettis-fixe.json";
import sucessIcon from "../../assets/img/icons/behaviour-type-success.svg";
import { useUser } from "../../hooks/useUser.jsx";

export default function ResultsModals({ resultsDatas }) {
	useEffect(() => {
		const dialog = document.querySelector("dialog");
		if (!dialog) return;
		dialog.scrollTo(0, 0);
	}, []);
	const navigate = useNavigate();

	const { screensTranslations } = useUser();
	const [isModalOpen, setIsModalOpen] = useState(true);

	if (!screensTranslations || !screensTranslations.game_results) return <Loading />;
	const currentScreenTranslations = screensTranslations.game_results;

	if (!resultsDatas) return navigate("/home");

	const { gameStats } = resultsDatas;

	function goRating() {
		setIsModalOpen(false);
		navigate("/rating");
	}
	function getResultsDetails() {
		if (isNaN(gameStats.level_score)) return;
		if (gameStats.level_score_range === "perfect")
			return {
				title: currentScreenTranslations.perfect_title,
				text: currentScreenTranslations.perfect_text,
				motivation_line: currentScreenTranslations.perfect_motivation_line,
			};
		if (gameStats.level_score_range === "congrats")
			return {
				title: currentScreenTranslations.congrats_title,
				text: currentScreenTranslations.congrats_text,
				motivation_line: currentScreenTranslations.congrats_motivation_line,
			};

		return {
			title: currentScreenTranslations.attention_title,
			text: currentScreenTranslations.attention_text,
			motivation_line: currentScreenTranslations.attention_motivation_line,
		};
	}

	let resultsDetails = getResultsDetails();

	return (
		<Modal className='results-modal' open={isModalOpen} onClose={goRating}>
			<div className='modal-content-container game-question-container'>
				<Lottie
					className='modal-content-container__celebration'
					animationData={celebrationConfettis}
					loop
					autoplay
				/>
				<Lottie
					className='modal-content-container__celebration-confettis-fixe'
					animationData={celebrationConfettisFixe}
					loop={false}
					autoplay
				/>
				<h1 className='titling-construction '>
					{currentScreenTranslations.results_screen_title}
				</h1>
				<h2>{resultsDetails.title}</h2>
				<div className='visual-results'>
					<img className='visual-results__cup' src={cup} alt='' />
					<div className='visual-results__score'>
						{gameStats.level_score}
						<img className='succes-indicator' src={sucessIcon} alt='' />
					</div>
				</div>
				<p className='results-explanation'>{resultsDetails.text}</p>
				<p className='motivation-line'>{resultsDetails.motivation_line}</p>
				<button
					className='cta cta--construction cta--round cta--button-icon cta--home'
					onClick={goRating}></button>
			</div>
		</Modal>
	);
}
